<template>
  <div class="report-data-content">
    <div class="flex-btw sticky">
      <h3 class="data-title">投放数据</h3>
      <el-button-group>
        <el-button
          v-for="item in dateList"
          :key="item.id"
          type="primary"
          size="mini"
          :plain="item.id != showDate"
          @click="changeDate(item)"
          >{{ item.name }}</el-button
        >
      </el-button-group>
    </div>
    <div class="data-label">
      数据为截止到当前时刻最新数据
    </div>
    <van-grid :column-num="4">
      <van-grid-item v-for="item in launchData" :key="item.label">
        <div class="grid-item">
          <p>{{ getData(item) }}</p>
          <div>{{ item.label }}</div>
        </div>
      </van-grid-item>
    </van-grid>
    <!-- <h3 class="data-title">{{ adResultTitle }}</h3>
    <van-grid :column-num="4" :border="false" :gutter="5">
      <van-grid-item v-for="item in adReportData" :key="item.label">
        <div class="grid-item">
          <p>{{ item.key }}</p>
          <div>{{ item.label }}</div>
        </div>
      </van-grid-item>
    </van-grid> -->
    <div v-if="detail.adProductReport">
      <h3 class="data-title">广告系列经营分析</h3>
      <van-cell>
        <!-- <template #label> -->
        <div>
          <div>商品ID：{{ detail.adProductReport.productId }}</div>
          <div>商品名称：{{ detail.adProductReport.productName }}</div>
          <div>产品名称：{{ detail.adProductReport.goodsName }}</div>
        </div>
        <!-- </template> -->
      </van-cell>
      <van-grid :column-num="4">
        <van-grid-item v-for="item in productReportData" :key="item.label">
          <div class="grid-item">
            <p>
              {{
                formatData(
                  getProductReportData(item.key, item.getType),
                  item.formatType
                ) || "—"
              }}
            </p>
            <div>{{ item.label }}</div>
          </div>
        </van-grid-item>
        <van-grid-item>
          <img
            class="report-img"
            v-if="detail.adProductReport.profitRateResult"
            :src="reportImg[detail.adProductReport.profitRateResult]"
            alt=""
          />
        </van-grid-item>
      </van-grid>
    </div>
    <van-cell-group title="广告组数据分析" v-if="adset.length">
      <van-cell v-for="(item, i) in adset" :key="i" :title="item.adset_name">
        <template #title>
          <div class="flex">
            <span>{{ item.adset_name }}</span>
            <span class="flex" @click="pausedAdset(item)">
              <span style="padding:0 8px;">关闭 </span>
              <van-switch
              :value="item.active"
              :disabled="item.disabled"
              size="22px"
              inactive-color="#dcdee0"
            />
            <span style="padding-left:8px;"> 开启</span>
            </span>
          </div>
        </template>
        <template #label>
          <van-grid :column-num="5">
            <van-grid-item v-for="keyObj in launchData" :key="keyObj.label">
              <div class="grid-item">
                <p>
                  {{ formatData(item[keyObj.key], keyObj.formatType) || "—" }}
                </p>
                <div>{{ keyObj.label }}</div>
              </div>
            </van-grid-item>
          </van-grid>
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>
<script>
import HIGHT from "@/assets/icon/high.png";
import MID from "@/assets/icon/middle.png";
import LOW from "@/assets/icon/low.png";
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
    showDate: {
      type: String,
      default: "today",
    },
    adset: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      reportImg: {
        HIGHT,
        MID,
        LOW,
      },
      launchData: [
        {
          label: "总花费",
          key: "spend",
        },
        {
          label: "本地订单",
          key: ["validOrderCount", "orderCount"],
          formatType: "local_order",
        },
        {
          label: "购买量",
          key: "purchase",
        },
        {
          label: "购买成本",
          key: "purchase_cost",
        },
        {
          label: "添加支付信息",
          key: "add_payment_info",
        },
        {
          label: "添加支付信息成本",
          key: "add_payment_info_cost",
        },
        {
          label: "点击",
          key: "unique_clicks",
        },
        {
          label: "点击成本",
          key: "cpc",
        },
        {
          label: "点击率",
          key: "ctr",
          formatType: "currentPercent",
        },
        {
          label: "ROAS",
          key: "purchase_roas",
        },
        {
          label: "购买转化率",
          key: "purchase_rate",
          formatType: "currentPercent",
        },
        {
          label: "预算",
          key: ["daily_budget", "lifetime_budget"],
          getType: "or",
          formatType: "centToUnit",
        },
        {
          label: "千次展示费用",
          key: "cpm",
        },
        {
          label: "链接点击量",
          key: "link_click",
        },
        {
          label: "链接点击率",
          key: "inline_link_click_ctr",
          formatType: "currentPercent",
        },
        {
          label: "链接点击成本",
          key: "cost_per_inline_link_click",
        },
      ],
      productReportData: [
        {
          label: "客单价",
          key: "amountPerCustomer",
        },
        // {
        //   label: "客单量",
        //   key: "countPerCustomer",
        // },
        {
          label: "ROI",
          key: "roi",
        },
        {
          label: "ROI临界点",
          key: "lowestRoi",
        },
        {
          label: "运费占比",
          key: "logisticsRate",
          formatType: "currentPercent",
        },
        {
          label: "采购占比",
          key: "buyRate",
          formatType: "currentPercent",
        },
        {
          label: "广告费占比",
          key: "adRate",
          formatType: "currentPercent",
        },
        {
          label: "平均每单广告费",
          key: "avgAdCost",
        },
        {
          label: "广告临界点",
          key: "adCriticalVal",
        },
        {
          label: "签收率",
          key: "signRate",
          formatType: "currentPercent",
        },
        {
          label: "订单量",
          key: ["codOrderCount", "onlineOrderCount"],
          getType: "and",
        },
        {
          label: "三项成本",
          key: "threeRate",
          formatType: "currentPercent",
        },
        {
          label: "选品",
          key: ["chooserName", "chooserId"],
          getType: "or",
        },
        {
          label: "设计",
          key: ["designerName", "designerId"],
          getType: "or",
        },
        {
          label: "其他占比",
          key: "otherRate",
          formatType: "currentPercent",
        },
        {
          label: "毛利率",
          key: ["signRate", "threeRate"],
          getType: "subtract",
          formatType: "currentPercent",
        },
      ],
      adssetData: [
        // adset_name
        {
          label: "点击量",
          key: "unique_clicks",
        },
        {
          label: "点击率",
          key: "ctr",
          formatType: "currentPercent",
        },
        {
          label: "花费",
          key: "spend",
        },
        {
          label: "购买成本",
          key: "purchase_cost",
        },
        {
          label: "添加支付成本",
          key: "add_payment_info_cost",
        },
      ],
      dateList: [
        {
          id: "today",
          name: "今天",
        },
        {
          id: "yesterday",
          name: "昨天",
        },
        {
          id: "threeday",
          name: "3天",
        },
        {
          id: "sevenday",
          name: "7天",
        },
        {
          id: "currentmonth",
          name: "本月",
        },
      ],
    };
  },
  computed: {
    adResultTitle() {
      return this.$route.name == "adgroups"
        ? "广告组经营分析"
        : "广告系列经营分析";
    },
  },
  methods: {
    changeDate(e) {
      this.$emit("change-date", e);
    },
    getProductReportData(key, type) {
      if (key instanceof Array) {
        let obj = {
          or: (k) =>
            this.detail.adProductReport[
              k.find((v) => this.detail.adProductReport[v])
            ],
          and: (k) =>
            k.reduce((pre, cur) => {
              return (pre += this.detail.adProductReport[cur]);
            }, 0),
          subtract: (k) =>
            this.detail.adProductReport[k[0]] -
            this.detail.adProductReport[k[1]],
        };
        return obj[type](key);
      }
      return this.detail.adProductReport[key];
    },
    formatData(num, type) {
      if (parseFloat(num)) {
        let obj = {
          percent: (num) => Math.round(parseFloat(num) * 10000) / 100 + "%",
          currentPercent: (num) =>
            (Math.round(parseFloat(num) * 1000) / 1000).toFixed(2) + "%",
          centToUnit: (num) => num / 100,
          default: (num) => Math.round(parseFloat(num) * 100) / 100,
        };
        return (obj[type] || obj.default)(num);
      }
      return num;
    },
    getData(item) {
      let data;
      if (item.getType) {
        data = this.detail[item.key[0]] || this.detail[item.key[1]];
      } else {
        data = this.detail[item.key];
      }
      if (item.formatType == "local_order") {
        let localOrder = this.detail.local_order;
        if(!localOrder) return "—"
        return `${localOrder[item.key[0]]}/${localOrder[item.key[1]]}`
      }
      return this.formatData(data, item.formatType) || "—";
    },
    pausedAdset(item) {
      if(item.disabled) {
        this.$toast(`广告组状态${item.status}，禁止操作`)
        return
      }
      this.$emit('click-adset', item)
    }
  },
};
</script>
<style lang="scss">
.report-data {
  .van-grid-item__content.van-grid-item__content--center {
    padding: 8px 3px;
  }
}
</style>
<style lang="scss" scoped>
.report-data-content {
  padding: 0 16px 10px;
  .flex-btw {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .report-img {
    height: 28px;
  }
}
.data-title {
  font-size: 14px;
  margin-top: 10px;
  color: #333;
}
.sticky {
  position: sticky;
  top: 0;
  width: 100%;
  padding: 0 16px;
  box-sizing: content-box;
  margin-left: -16px;
  z-index: 999;
  background: #fff;
  border-bottom: 1px solid #eee;
}
.grid-item {
  text-align: center;
  p {
    font-size: 15px;
    word-break: break-all;
    // font-weight: 600;
  }
  div {
    font-size: 12px;
    color: #666;
  }
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}
.data-label {
  font-size: 12px;
  color: #666;
  padding: 8px 0;
}
</style>