<template>
    <div class="title-msg">
        <p>
            <span class="label">当前广告账户：</span>
            <span>{{info.accountName}}</span>
        </p>
        <p v-if="info.objType == 'adset'">
            <span class="label">广告系列名称：</span>
            <span>{{info.campaignName}}</span>
        </p>
        <p>
            <span  class="label">
                广告{{info.objType == 'adset'?'组':'系列'}}名称：
            </span>
            <span>{{info.objName}}</span>
        </p>
    </div>
</template>
<script>
export default {
    props:{
        info:{
            type:Object,
            default:()=>{}
        }
    },
    data () {
        return {
            
        }
    }
}
</script>
<style lang="scss">
    .title-msg{
        padding: 10px 16px;
        border-bottom: 1px solid #ebedf0;
        p{
            font-size: 13px;
            color: #666;
            line-height: 28px;
            display: flex;
            align-items: flex-start;
            span.label{
                color: #333;
                font-size: 14px;
                flex: 100px 0 0;
            }
        }
    }
</style>