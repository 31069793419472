<template>
  <div class="result-content">
    <div class="nav-bar"></div>
    <div class="tabs-wrap">
      <van-tabs
      @change="changeTab"
      color="#1b82d2"
      v-model="active"
      title-active-color="#1b82d2"
      :line-width="lineWidth"
    >
      <van-tab
        v-for="item in status"
        :key="item.id"
        :title="item.name"
      ></van-tab>
    </van-tabs>
    </div>
    <div
      v-show="lists[status[active]['id']] && lists[status[active]['id']].length"
    >
      <template v-for="(item, i) in lists[status[active]['id']]">
        <account-card
          :key="item.objId+active"
          :info="item"
          @update-info="updateInfo($event, i)"
        ></account-card>
      </template>
      <van-divider>没有更多了~</van-divider>
    </div>
    <van-empty
      v-show="!(lists[status[active]['id']] && lists[status[active]['id']].length)"
    >
      <template #description>
        <div>
          暂无<span class="empty-desciption">{{ status[active].name }}</span>数据，试试其它选项~  
        </div>
      </template>
    </van-empty>
    <nav-filter
      title="选择筛选条件"
      :show.sync="showFilter"
      @confirm="confirmFilter"
      web-filter
    ></nav-filter>
    <el-backtop target=".result-content" :right="10"></el-backtop>
    <replay :list="loginList" :show.sync="showReplay"></replay>
    <much-more @click-replay="viewReplay" @click-filters="showFilter = !showFilter"></much-more>
  </div>
</template>
<script>
import navFilter from "./components/navFilter";
import accountCard from "./components/accountCard";
import { accountQuery, getLoginList } from "@/api/autoRulesResult";
import { mapState } from "vuex";
import replay from "./components/replay";
import muchMore from "./components/muchMore";
export default {
  components: {
    navFilter,
    accountCard,
    replay,
    muchMore
  },
  data() {
    return {
      active: 0,
      status: [
        {
          name: "变更预算",
          id: "CHANGE_BUDGET",
        },
        {
          id: "PAUSE",
          name: "关闭组/系列",
        },
        {
          id: "NOTIFICATION",
          name: "提示信息",
        },
      ],
      lists: {},
      showFilter: false,
      batchNos: [],
      keyWord: "",
      showReplay: false,
      processed: "",
      loginList: []
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userInfo.data.id,
    }),
    lineWidth() {
      return parseInt(document.body.clientWidth / this.status.length) + "px";
    },
  },
  methods: {
    viewReplay() {
      if (this.loginList.length) {
        this.showReplay = true;
      } else {
        this.$showLoading();
        getLoginList().then(res => {
          this.$hideLoading();
          if(res.code == 0){
            this.showReplay = true;
            res.data.validThirdLoginUsers.map(v => {
              if(v.fbAdAccounts && v.fbAdAccounts.length){
                v.fbAdAccounts.map(fbAd => {
                  if (fbAd.accountStatus == 1) {
                    let item = this.loginList.find(l => l.id == fbAd.id)
                    if (!item) {
                      this.loginList.push(fbAd)
                    }
                  }
                })
              }
            })
          }
        })
      }
    },
    changeTab(e) {
      if (
        !this.lists[this.status[e]["id"]] ||
        !this.lists[this.status[e]["id"]].length
      ) {
        this.getList();
      }
    },
    getList() {
      this.$set(this.lists, this.status[this.active]["id"], []);
      this.$showLoading();
      let executionTypes =
        this.status[this.active]["id"] == "CHANGE_BUDGET"
          ? ["CHANGE_BUDGET", "CHANGE_CAMPAIGN_BUDGET"]
          : [this.status[this.active]["id"]];
      const data = {
        userId: this.userId,
        keyword: this.keyWord,
        batchNos: this.batchNos,
        executionTypes: executionTypes,
      };
      if (this.processed !== "") {
        data.processed = this.processed
      }
      accountQuery(data)
        .then((res) => {
          this.$hideLoading();
          if (res.code == 0) {
            this.$set(this.lists, this.status[this.active]["id"], res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$hideLoading();
        });
    },
    confirmFilter(e) {
      this.keyWord = e.keyword;
      switch (e.switchChecked) {
        case "0":
          this.processed = ""
          break;
        case "1":
          this.processed = false
          break;
        case "2":
          this.processed = true
          break;
      }
      this.lists = {};
      this.getList();
    },
    resetFilter() {},
    onSearch() {
      this.$nextTick(() => {
        this.getList();
      });
    },
    updateInfo(item, i) {
      this.lists[this.status[this.active]['id']][i] = item;
    }
  },
  mounted() {
    this.batchNos = this.$route.query.batchNos
      ? this.$route.query.batchNos.split(",")
      : [];
    this.getList();
  },
};
</script>
<style lang="scss">
$m-theme: #1b82d2;
.result-content {
  height: 100%;
  overflow-y: auto;
  background: #f1f1f1;
  padding-bottom: 10px;
  .nav-bar {
    height: 44px;
    .el-icon-refresh{
      margin-right: 10px;
      font-size: 18px;
    }
  }
  .tabs-wrap {
    height: 45px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #eee;
    z-index: 10;
  }
  .search-wrap {
    position: fixed;
    top: 45px;
    left: 0;
    z-index: 1000;
    width: 100%;
  }
  .search-place {
    height: 55px;
  }
}
.van-nav-bar {
  background-color: $m-theme;
  color: #fff !important;
}
.van-nav-bar * {
  color: #fff !important;
}
.line-1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.separate {
  height: 2px;
}
.min-shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
.empty-desciption{
  font-weight: bold;
  padding: 0 5px;
  color: $m-theme;
  font-size: 1.1em;
}
</style>