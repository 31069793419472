<template>
  <van-popup v-model="showPopup" closeable round position="bottom">
    <div class="replay-popup">
      <h2>重新采集</h2>
      <van-form @submit="submit">
        <div class="content">
          <div class="report-data">
            <van-field
              readonly
              clickable
              name="picker"
              :value="rule.name"
              label="执行方案"
              placeholder="请选择自动规则执行方案"
              @click="showPicker = true"
            />
            <van-popup v-model="showPicker" position="bottom">
              <van-picker
                show-toolbar
                :columns="rulesList"
                value-key="name"
                @confirm="onConfirm"
                @cancel="showPicker = false"
              />
            </van-popup>
            <van-field name="radio" label="广告账户">
              <template #input>
                <van-radio-group v-model="radio" direction="horizontal">
                  <van-radio name="1">全选</van-radio>
                  <van-radio name="2">自定义</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <el-collapse-transition>
              <div class="account-list" v-show="radio == '2'">
                <van-cell-group title="请选择广告账户">
                  <van-search
                    v-model="value"
                    clearable
                    placeholder="请输入搜索关键词"
                    @search="onSearch"
                  />
                  <van-cell
                    v-for="item in showList"
                    :key="item.id"
                    @click="selcetData(item.id)"
                  >
                    <template #right-icon>
                      <div class="cust-icon">
                        <van-icon
                          v-show="selectList.includes(item.id)"
                          name="success"
                        />
                      </div>
                    </template>
                    <template #title>
                      <span
                        :class="
                          selectList.includes(item.id) ? 'custom-title' : ''
                        "
                      >
                        {{ item.name }}
                      </span>
                    </template>
                  </van-cell>
                </van-cell-group>
              </div>
            </el-collapse-transition>
          </div>
          <van-button type="info" id="autoRulesSubmit" native-type="submit">提 交</van-button>
        </div>
      </van-form>
    </div>
  </van-popup>
</template>
<script>
import reportData from "./reportData";
import { getRules, automation } from "@/api/autoRules.js";
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    reportData,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      columns: [],
      showPicker: false,
      rulesList: [],
      rule: {},
      radio: "1",
      selectList: [],
      value: ""
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.userInfo
    }),
    ...mapGetters(["token", "accountId"]),
    showList() {
        return this.list.filter(v => v.name.toLowerCase().includes(this.value.toLowerCase()) || (v.id + '').includes(this.value))
    },
    showPopup: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
  },
  methods: {
    onSearch(){

    },
    onConfirm(e) {
      this.rule = e;
      this.showPicker = false;
    },
    selcetData(id) {
      this.selectList.includes(id)
        ? (this.selectList = this.selectList.filter((v) => v != id))
        : this.selectList.push(id);
    },
    getRules() {
      if (!this.rulesList.length) {
        getRules({ _token: this.token })
          .then((res) => {
            if (res.data && res.data.length) {
              this.rulesList = res.data;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    submit() {
      const { rule, selectList } = this;
      let ruleId = rule.id;
      if (!ruleId && ruleId != 0) {
        this.$message.error("请选择自动化规则方案");
        return;
      }
      if (this.radio == 2 && !selectList.length) {
        this.$message.error("请选择广告账号");
        return;
      }
      let _token = this.token;
      let scopeValue =
        this.radio == 1
          ? this.list.map((v) => v.id).join()
          : selectList.join();
    //   let _token = "527663b983e78d0a5817521bab8e1f2f";
      this.showPopup = false;
      this.$message.info("自动化规则开始执行，请注意查收钉钉通知。");
      const userId = this.user.data.id;
      automation({ scopeValue, ruleId, userId, _token })
        .then((res) => {
          //   console.log(res);
          setTimeout(() => {
            this.$message.success("自动化规则执行成功");
          }, 30 * 1000);
        })
        .catch((err) => {
          this.$message.error("自动化规则执行失败");
          console.log(err);
        });
    },
  },
  mounted() {
    this.getRules();
  },
};
</script>
<style lang="scss">
$danger: #ae0000;
$success: #4cad54;
$m-theme: #1b82d2;
$selected: #1989fa;
.flex {
  display: flex;
}
.replay-popup {
  h2 {
    font-size: 16px;
    text-align: center;
    margin-top: 16px;
  }
  .content {
    height: 500px;
    display: flex;
    flex-direction: column;
    .cust-icon {
      width: 18px;
      display: flex;
      align-items: center;
      color: $selected;
    }
    .custom-title {
      color: $selected;
    }
    .report-data {
      flex: 1;
      overflow-y: auto;
      .account-list {
        height: 360px;
        overflow-y: auto;
        padding: 0 16px;
      }
      .danger {
        color: $danger;
      }
      .success {
        color: $success;
      }
    }
  }
}
</style>